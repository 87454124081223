import React, { useRef } from "react";

import './Contact2.css';
import emailjs from 'emailjs-com'


const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();


                // Popup message after send button is clicked
                const feedbackEl = document.querySelector(".feedback");
                feedbackEl.setAttribute("class", "feedback");
                setTimeout(function() {
                feedbackEl.setAttribute("class", "feedback hidden");
                }, 3000);


        //    Service Id        Template Id                 Public Key (Account Tab) 
        emailjs.sendForm('service_hsunksm', 'template_rxc8sbg', form.current, 'Rj5e7bWJla-kOEL0H')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset()
    };


    return (
        <>

        
              
            <div className="contactform2">
 
              <div class="contact2-container">
                  <div class="contact-form-content container">

                      <div className="right-side">

                        <div className="form-hide">
                          <h1>  It's time to feel better!</h1>
                          <h2>Creating stability in recovery</h2>
                        </div>

                          <div class="topic-text"><p>SEND US A MESSAGE</p>
                              <i><p>We'll keep your contact information strictly confidential.</p></i></div>
                                <form ref={form} onSubmit={sendEmail}>
                                  <div class="input-box">
                                  <input type="text" name='name' placeholder='Your Name' required />
                                  </div>
                                  <div class="input-box">
                                  <input type="email" name='email' placeholder='Your Email' required />
                                  </div>
                                  <div class="input-box">
                                  <input type="tel" name='phone' placeholder='Your Phone' required />
                                  </div>

                                  <div className="radio-button">
                                  <p>Have you been diagnosed with chronic depression? 
                                  <br/><input className="radio-ans" type="radio" id="age1" name="age" value="Yes"/>
                                  <label for="age1">&nbsp;Yes</label> &nbsp;&nbsp;<input className="radio-ans" type="radio" id="age2" name="age" value="No"/>
                                  <label for="age2">&nbsp;No</label></p>
                                  </div>


                                  <div class="input-box message-box">
                                  <textarea name="message" rows="4" placeholder="How can we help?" required ></textarea>
                                  </div>

                                  <input className="contact-btn" type="submit" value="Submit" />

                                  <div className="textarea2 feedback hidden">
                                  <textarea name="message2" cols="50" rows="1" required>Message Sent to Kings TMS Clinic!</textarea>
                                  </div>

                                </form>
                          </div>
                      </div>
              </div>

          </div>

        </>
    )
}

export default ContactForm